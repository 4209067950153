import { notification, Spin } from "antd";
import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useRouteLoaderData } from "react-router-dom";
import { selectCurrentUser, setToken, setUserProfile } from "../../redux/authSlice";
import Cookies from "js-cookie";
import './style.css'
import LayoutFull from "./layoutFull";
import useWindowDimensions from "../../hooks/windowDimentions";
import LayoutFullMobile from "./layoutFullMobile";

export default function Layout() {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch()
  const accessToken = Cookies.get("accessToken")
  const user = useSelector(selectCurrentUser)

  
  const { width, height } = useWindowDimensions()

  if (accessToken) {
    if(width > 400)
      return (
        <LayoutFull height={height}>
          <>
            {contextHolder}
            <Outlet />
          </>
        </LayoutFull>
      );
    else
      return (
        <LayoutFullMobile  height={height}>
          <>
            {contextHolder}
            <Outlet />
          </>
        </LayoutFullMobile>
      );
  }
  return (
    <div className='containerTable' style={{height: window.innerHeight}}>
      <div className='containerCell' style={{height: window.innerHeight}}>
        <div className='form'>
          {contextHolder}
          <Suspense fallback={<Spin />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
    </div>
  );
}

