import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { ImageRemoveRequest, ImageRemoveResponse, ImageUploadRequest, ImageUploadResponse } from '../../types/imagesTypes'
import { API_PATH, BASE_URL } from '../../constants'

export const apiImages = createApi({
  reducerPath: 'apiImages',
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    imageUpload: builder.mutation<ImageUploadResponse, ImageUploadRequest>({
      query: (credentials) => ({
        url: 'image/upload',
        method: 'POST',
        body: credentials,
      }),
    }),
    imageRemove: builder.mutation<ImageRemoveResponse, ImageRemoveRequest>({
      query: (credentials) => ({
        url: 'image/remove',
        method: 'DELETE',
        body: credentials,
      }),
    }),
  }),
})

export const { useImageUploadMutation, useImageRemoveMutation } = apiImages
