import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { API_PATH, BASE_URL } from '../../constants';

export interface User {
  id: string;
  password: string;
  email: string;
  name: string;
  surname?: string;
  role: string;
}

export interface UserResponse {
  userProfile: User
  token: string
}
export interface CurrentUserResponse {
  userProfile: User
}
export interface ActivationResponse {
  code: number
  message: string
}

export interface LoginRequest {
  username: string
  password: string
}

export interface RegistrationRequest {
  password: string;
  email: string;
  name: string;
  surname?: string;
  role: string;
}
export interface ActivationRequest {
  code: string;
}

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
    }),
    registaration: builder.mutation<UserResponse, RegistrationRequest>({
      query: (credentials) => ({
        url: 'auth/register',
        method: 'POST',
        body: credentials,
      }),
    }),
    activation: builder.mutation<ActivationResponse, ActivationRequest>({
      query: (credentials) => ({
        url: 'auth/activate',
        method: 'POST',
        body: credentials,
      }),
    }),
    protected: builder.mutation<{ message: string }, void>({
      query: () => 'protected',
    }),
    getCurrentUser: builder.query<CurrentUserResponse, void>({
      query: () => 'current_user',
    }),
    changeRoleToStudent: builder.mutation<CurrentUserResponse, void>({
      query: () => ({
        url: 'auth/change-role/student',
        method: 'PUT',
      })
    }),
    changeRoleToRepetitor: builder.mutation<CurrentUserResponse, void>({
      query: () => ({
        url: 'auth/change-role/repetitor',
        method: 'PUT',
      })
    }),
  }),
})

export const { useGetCurrentUserQuery, useLoginMutation, useRegistarationMutation, useActivationMutation, useProtectedMutation, useChangeRoleToStudentMutation, useChangeRoleToRepetitorMutation } = api
