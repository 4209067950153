import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import { Event, EventsResponse } from '../types/eventsTypes'
import { CommentsResponse, Homework, HomeworksResponse, Task, TasksResponse, Comment } from '../types/homeworksTypes'

type HomeworksState = {
  homeworks: Homework[],
  tasks?: Task[] | null,
  comments?: Comment[] | null,
  homework?: Homework | null
}

const slice = createSlice({
  name: 'homework',
  initialState: { homeworks: [] , homework: null, tasks: null, comments: null } as HomeworksState,
  reducers: {
    setHomeworks: (
      state,
      {
        payload: { homeworks },
      }: PayloadAction<HomeworksResponse>
    ) => {
      state.homeworks = homeworks
    },
    setTasks: (
      state,
      {
        payload: { tasks },
      }: PayloadAction<TasksResponse>
    ) => {
      state.tasks = tasks
    },
    setComments: (
      state,
      {
        payload: { comments },
      }: PayloadAction<CommentsResponse>
    ) => {
      state.comments = comments
    },
    setHomework: (
      state,
      {
        payload: homework ,
      }: PayloadAction<Homework | null>
    ) => {
      state.homework = homework
    },
    homeworkAddToList: (
      state,
      {
        payload: homework ,
      }: PayloadAction<Homework>
    ) => {
      state.homeworks?.push(homework)
    },
    taskAddToList: (
      state,
      {
        payload: task ,
      }: PayloadAction<Task>
    ) => {
      state.tasks?.push(task)
    },
    homeworksAddToList: (
      state,
      {
        payload: homeworks ,
      }: PayloadAction<Homework[]>
    ) => {
      state.homeworks?.push(...homeworks)
    },
    commentAddToList: (
      state,
      {
        payload: comment ,
      }: PayloadAction<Comment>
    ) => {
      state.comments?.push(comment)
    },
    homeworkEditOnList: (
      state,
      {
        payload: homework ,
      }: PayloadAction<Homework>
    ) => {
      state.homeworks = state.homeworks.map(item => item._id !== homework._id ? item : homework)
      state.homework = homework;
    },
    taskEditOnList: (
      state,
      {
        payload: task ,
      }: PayloadAction<Task>
    ) => {
      state.tasks = state.tasks?.map(item => item._id !== task._id ? item : task)
    },
    taskChangeStatusAnswer: (
      state,
      {
        payload: taskId ,
      }: PayloadAction<string | undefined>
    ) => {
      state.tasks = state.tasks?.map(item => item._id !== taskId ? item : { ...item, status: "answered"})
    },
    taskChangeStatusComment: (
      state,
      {
        payload: taskId ,
      }: PayloadAction<string | undefined>
    ) => {
      state.tasks = state.tasks?.map(item => item._id !== taskId ? item : { ...item, status: "commented"})
    },
    taskDeleteFromList: (
      state,
      {
        payload: id ,
      }: PayloadAction<string>
    ) => {
      state.tasks = state.tasks?.filter(item => item._id !== id)
    },
    homeworkDeleteFromList: (
      state,
      {
        payload: id ,
      }: PayloadAction<string>
    ) => {
      state.homeworks = state.homeworks?.filter(item => item._id !== id);
      state.homework = null;
      state.tasks = null
    },
    currentHomeworkClear: (
      state
    ) => {
      state.homework = null;
      state.tasks = null
    },
  },
  
})

export const { 
  setHomework, 
  taskDeleteFromList, 
  setTasks, 
  setHomeworks, 
  homeworkDeleteFromList, 
  homeworkAddToList, 
  homeworkEditOnList, 
  homeworksAddToList, 
  taskAddToList, 
  taskEditOnList, 
  currentHomeworkClear ,
  setComments,
  commentAddToList,
  taskChangeStatusAnswer,
  taskChangeStatusComment
} = slice.actions

export default slice.reducer

export const selectHomeworks = (state: RootState) => state.homeworks.homeworks
export const selectHomework = (state: RootState) => state.homeworks.homework
export const selectTasks = (state: RootState) => state.homeworks.tasks
export const selectComments = (state: RootState) => state.homeworks.comments


