import React from 'react';
import { Menu, MenuProps } from 'antd';
import { navigationRepetitor, navigationStudent } from './navigation';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../redux/authSlice';
interface IHeaderProps{
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
  isMobile: boolean;
}
const MenuSider: React.FC<IHeaderProps> = ({setCollapsed, isMobile}) => {
  const navigate = useNavigate();
  const user = useSelector(selectCurrentUser)
  const navigation = user?.role === "repetitor" ? navigationRepetitor : navigationStudent
  const onClick: MenuProps['onClick'] = (e) => {
    navigate('/' + e.key)
    if(isMobile)
      setCollapsed(true)
  };
  return <Menu
    theme="light"
    mode="inline"
    onClick={onClick}
    defaultSelectedKeys={['1']}
    items={ navigation }
    style={ {height: "100%", zIndex: 100} }
  />
};

export default MenuSider;