import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../redux'
import { IRepetitorsFilter, RepetitorApproveRequest, RepetitorsResponse } from '../../types/repetitorsTypes'
import { API_PATH, BASE_URL } from '../../constants'

export const apiRepetitors = createApi({
  reducerPath: 'apiRepetitors',
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/${API_PATH}/`,
    prepareHeaders: (headers, { getState }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    },
  }),
  endpoints: (builder) => ({
    repetitorAccept: builder.mutation<void, RepetitorApproveRequest>({
      query: (credentials) => ({
        url: 'repetitor/approve',
        method: 'PUT',
        body: credentials,
      }),
    }),
    repetitorReject: builder.mutation<void, RepetitorApproveRequest>({
      query: (credentials) => ({
        url: 'repetitor/reset_approve',
        method: 'PUT',
        body: credentials,
      }),
    }),
    getRepetitors: builder.query<RepetitorsResponse, IRepetitorsFilter>({
      query: (filter) => {
        let searchParams = filter.repetitorApproved !== undefined ? `?repetitorApproved=${filter.repetitorApproved}` : '';
        return 'repetitors' + searchParams;
      },
    }),
  }),
})

export const { useGetRepetitorsQuery, useRepetitorAcceptMutation, useRepetitorRejectMutation } = apiRepetitors
