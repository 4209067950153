import React, { Suspense, useState } from 'react';
import { Button, Layout, Menu, Spin, theme } from 'antd';
import HeaderLayout from './Header';
import MenuHeader from './menuSider';

const { Header, Sider, Content } = Layout;


const LayoutFullMobile: React.FC<{children: React.ReactElement, height: number}> = ({ children, height }) => {
  const [collapsed, setCollapsed] = useState(true);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const siderStyle: React.CSSProperties = {
    overflow: 'auto',
    height: height - 64,
    position: 'fixed',
    insetInlineStart: 0,
    zIndex:100,
    bottom: 0,
    scrollbarWidth: 'thin',
    scrollbarGutter: 'stable',
  };
  
  return (
    <Layout >
      <Sider theme={'light'} 
        trigger={null} 
        collapsible 
        collapsed={collapsed} 
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
        style={siderStyle}>
        <div className="demo-logo-vertical" />
        <MenuHeader collapsed={collapsed} setCollapsed={setCollapsed} isMobile={true} />

      </Sider>
      <Layout >
        <HeaderLayout setCollapsed={setCollapsed} collapsed={collapsed} />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: height - 112,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Suspense fallback={<Spin />}>
              {children}
            </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
};

export default LayoutFullMobile;