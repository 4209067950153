import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '.'
import { Repetitor, RepetitorsResponse } from '../types/repetitorsTypes'

type RepetitorsState = {
  repetitors: Repetitor[],
}

const slice = createSlice({
  name: 'repetitors',
  initialState: { repetitors: []} as RepetitorsState,
  reducers: {
    setRepetitors: (
      state,
      {
        payload: { repetitors },
      }: PayloadAction<RepetitorsResponse>
    ) => {
      state.repetitors = repetitors
    },
  },
})

export const { setRepetitors  } = slice.actions

export default slice.reducer

export const selectRepetitors = (state: RootState) => state.repetitors.repetitors

